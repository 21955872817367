<template>
    <base-layout :public="true" :fill-height="true">
        <v-col class="align-center">
            <v-row justify="center" class="pb-10"><img src="@/assets/TK_logo_dark.svg" class="logo"/></v-row>
            <template v-if="!isSentEmail">
                <v-row>
                    <v-card class="mx-auto px-6" max-width="600">
                        <v-container fluid pa-10>
                            <v-row justify="center" class="pb-2">
                                <div class="title regalBlue--text">Forgot password?</div>
                            </v-row>
                            <v-row justify="center">
                                <div class="sub-title martinique--text">
                                    Enter your email to reset your password
                                </div>
                            </v-row>
                            <v-card-text class="pt-5 pb-0">
                                <span class="email-text casper--text">EMAIL</span>
                                <v-text-field
                                    v-model="email"
                                    :disabled="isLoading"
                                    :class="[isEmailHasError ? 'forgot-password-text-field-error' : 'forgot-password-text-field']"
                                    class="pt-0 mt-0 pb-8"
                                    background-color="whiteLilac"
                                    hide-details
                                ></v-text-field>
                            </v-card-text>
                            <v-row justify="center" class="pb-5">
                                <v-col cols="8">
                                    <v-btn @click="onForgotPassword()" :loading="isLoading" color="mangoTango" block dark>
                                        <span class="reset-password-text">Reset password</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-row>
                <v-row justify="center" class="pt-5">
                    <v-btn @click="$router.push({ name: 'login' })" :disabled="isLoading" color="paleSky" text>
                        <span class="cancel-text">Cancel</span>
                    </v-btn>
                </v-row>
            </template>
            <template v-else>
                <v-row>
                    <v-card class="mx-auto px-6" max-width="600">
                        <v-container fluid pa-10>
                            <v-row justify="center" class="pb-2">
                                <div class="title regalBlue--text">Email has been sent!</div>
                            </v-row>
                            <v-row justify="center" class="pt-4 pb-8">
                                <v-col cols="8" class="text-center">
                                    <div class="sub-title martinique--text">
                                        Please click the link in that message to create a new password.
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row justify="center" class="pb-5">
                                <v-col cols="8">
                                    <v-btn @click="$router.push({ name: 'login' })" color="mangoTango" block dark>
                                        <span class="log-in-text">Log in</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-row>
            </template>
        </v-col>
    </base-layout>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';

import { REGEX_EMAIL_PATTERN } from '@/core/consts/const';

export default {
    name: 'ForgotPassword',
    data() {
        return {
            email: '',
            isLoading: false,
            isSentEmail: false,

            isEmailHasError: false,
        };
    },
    created() {
        window.addEventListener('keydown', this.onPressKey, false);
    },
    destroyed() {
        window.removeEventListener('keydown', this.onPressKey, false);
    },
    computed: {
        backgroundColor() {
            return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.whiteLilac : this.$vuetify.theme.themes.light.whiteLilac;
        },
    },
    methods: {
        ...mapActions('restorePassword', {
            forgotPassword: 'forgotPassword',
        }),
        onPressKey(e) {
            e = e || window.event;
            if (e.keyCode === 13) {
                if (!this.isSentEmail && !this.isLoading) {
                    this.onForgotPassword();
                } else {
                    if (!this.isLoading) {
                        this.$router.push({ name: 'login' });
                    }
                }
            }
        },
        async onForgotPassword() {
            this.isEmailHasError = false;
            if (this.email && REGEX_EMAIL_PATTERN.test(this.email.toLowerCase())) {
                this.isLoading = true;
                await this.forgotPassword({
                    email: this.email,
                }).then((res) => {
                    if (res) {
                        this.isSentEmail = true;
                    } else {
                        this.isEmailHasError = true;
                        this.isLoading = false;
                    }
                });
            } else {
                if (!this.email) {
                    this.isEmailHasError = true;
                    Vue.toasted.error('Email field must not be empty');
                }
                if (this.email && !REGEX_EMAIL_PATTERN.test(this.email.toLowerCase())) {
                    this.isEmailHasError = true;
                    Vue.toasted.error('Email not valid');
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.logo {
    width: 120px;
}

.title {
    font-size: 32px !important;
    line-height: 38px;
    letter-spacing: -0.114286px !important;
}

.sub-title {
    font-size: 15px;
    line-height: 22px;
}

.email-text {
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 1.21333px;
}

.log-in-text {
    font-size: 15px;
    line-height: 21px;
}

.cancel-text {
    font-size: 14px;
    line-height: 17px;
}
</style>
